import React, { useState } from "react";
import { connect } from "react-redux";
import * as fuiRedux from "../../../redux/fui/index";
import { checkWBSECode } from "./CommonFunctions";
import * as FormField from "./IndividualFormFields";
import PropTypes from "prop-types";
import GeneralForm from "./GeneralForm";
import { Grid } from "@material-ui/core";

/**
 * Quasar form
 * @author Andres Mc Leod
 * @version 1.0
 * @see Used in component: AppStepper
 */
const Quasar = ({
  configurationType,
  setIsSPAllValid,
  values, // the value would be changed by user's input
  updateQuasarForm, //the function to update SPForm values which is from redux
  aSelectedInstance, // the instance that selected by users in step two
  email, // user's email comes from token
  history,
}) => {
  const config = configurationType === "quick";
  // check whether WBSECode and MonthlyTicketVolume are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);

  // get user's input
  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    event.preventDefault();
    updateQuasarForm(fieldName, event.target.value);
    if (fieldName === "WBSEcode") {
      setIsWBSECodeValid(checkWBSECode(event.target.value));
    }
    if (fieldName === "ProjectName") {
      if (value.length > 32 || value.length < 3){
        setIsProjectNameValid(false)
      } else {
        setIsProjectNameValid(true)
      }
    }
  };

  // get user's EID from email
  const createdBy = email ? email.split("@")[0] : "";

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateQuasarForm(fieldName, value);
  };

  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updateQuasarForm("ConfigurationType", configurationType);

  updateQuasarForm(
    "EndToEndUId",
    aSelectedInstance.EndToEndUId === ""
      ? aSelectedInstance.DMSId
      : aSelectedInstance.EndToEndUId
  );

  // change validation status
  !isWBSECodeValid && !isProjectNameValid ? setIsSPAllValid(false) : setIsSPAllValid(true);

  return (
    <GeneralForm
        FormId={6}
        ClientContractFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={4}>
                {FormField.SAPContractIDField(handleChange, aSelectedInstance)}
              </Grid>
              <Grid item xs={4}>
                {FormField.ClientNameField(handleChange, aSelectedInstance)}
              </Grid>
              <Grid item xs={4}>
                {FormField.EndToEndUIdField(aSelectedInstance)}
              </Grid>
              <Grid item xs={4}>
                {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
              </Grid>
              <Grid item xs={4}>
                {FormField.WBSECodeField(handleChange, values, isWBSECodeValid)}
              </Grid>
            </Grid>
          </div>
        }

        EnvironmentFields={
          <div>
            <div style={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <p>Environment details have been successfully fetched from the selected instance details provided in the previous step.</p>
                </Grid>
              </Grid>
            </div>
          </div>
        }

        ApplicationFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={6}>
                {FormField.AdminDistributionGroupField(handleChange, values)}
              </Grid>
              {!config &&
                <Grid item xs={6}>
                  {FormField.UsersDistributionGroupField(handleChange, values)}
                </Grid>}
              <Grid item xs={6}>
                {FormField.PlatformTypeField(handleChange, aSelectedInstance)}
              </Grid>
            </Grid>
          </div>
        }
        history={history}
        NoConfig={undefined}
        belong={"pam"}
      ></GeneralForm>
  );
};
Quasar.propTypes = {
  /**
   * Users email
   */
  email: PropTypes.string,
  /**
   * The configuration type of the form
   */
  configurationType: PropTypes.string,
  /**
   * Function update DEvOpsAnalytics form
   */
  updateQuasarForm: PropTypes.func,
  /**
   * Object containing current Quasar form
   */
  values: PropTypes.object,
  /**
   * Contains selected instance from the stepper
   */
  aSelectedInstance: PropTypes.object,
  /**
   * Sets true if all fields are valid for submit
   */
  setIsSPAllValid: PropTypes.func,
};
const MSTP = (state, ownProps) => ({
  configurationType: state.fui.configurationType,
  values: state.fui.QuasarForm,
  aSelectedInstance: state.fui.aSelectedInstance,
  email: state.auth.userDetails.email,
});

export default connect(MSTP, {
  updateQuasarForm: fuiRedux.updateQuasarForm,
})(Quasar);
