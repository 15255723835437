import React, { useState } from "react";
import { connect } from "react-redux";
import * as fuiRedux from "../../../redux/fui/index";
import { checkWBSECode } from "./CommonFunctions";
import * as FormField from "./IndividualFormFields";
import PropTypes from "prop-types";
import GeneralForm from "./GeneralForm";

//Choose a configuration type and enter into quick or full configuration components
/**
 * Sharepoint form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: AppStepper
 */
const Sharepoint = ({
  configurationType,
  setIsSPAllValid,
  values, // the value would be changed by user's input
  updateSPForm, //the function to update SPForm values which is from redux
  aSelectedInstance, // the instance that selected by users in step two
  email, // user's email comes from token
  history,
}) => {
  const config = configurationType === "quick";
  // check whether WBSECode and MonthlyTicketVolume are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);

  // get user's input
  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    event.preventDefault();
    updateSPForm(fieldName, event.target.value);
    if (fieldName === "WBSEcode") {
      setIsWBSECodeValid(checkWBSECode(event.target.value));
    }
    if (fieldName === "ProjectName") {
      if (value.length > 32 || value.length < 3){
        setIsProjectNameValid(false)
      } else {
        setIsProjectNameValid(true)
      }
    }
  };

  // get user's EID from email
  const createdBy = email ? email.split("@")[0] : "";

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateSPForm(fieldName, value);
  };

  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updateSPForm("ConfigurationType", configurationType);

  updateSPForm(
    "EndToEndUId",
    aSelectedInstance.EndToEndUId === ""
      ? aSelectedInstance.DMSId
      : aSelectedInstance.EndToEndUId
  );

  // change validation status
  !isWBSECodeValid && !isProjectNameValid ? setIsSPAllValid(false) : setIsSPAllValid(true);

  return (
    <GeneralForm
      FormId={11}
      LeftColumnFields={
        <div>
          {FormField.EndToEndUIdField(aSelectedInstance)}
          {FormField.DeployRegionField(handleChange, aSelectedInstance)}
          {FormField.ClientNameField(handleChange, aSelectedInstance)}
          {FormField.VendorNameField(values.VendorName)}
          {FormField.SAPContractIDField(handleChange, aSelectedInstance)}
        </div>
      }
      RightColumnFields={
        <div>
          {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
          {FormField.PlatformTypeField(handleChange, aSelectedInstance)}
          {FormField.CustomerEnterpriseIDField(createdBy, config)}

          {!config && FormField.MobileNumberField(handleChangeMobileNo, values)}

          {FormField.WBSECodeField(handleChange, values, isWBSECodeValid)}

          {!config && FormField.UsersField(handleChange, values)}

          {!config && FormField.ServiceGroupField(handleChange, values)}

          {FormField.AdminDistributionGroupField(handleChange, values)}

          {!config &&
            FormField.UsersDistributionGroupField(handleChange, values)}

          {FormField.TermsAndConditionsField(
            handleChange,
            values,
            isAAAMTermsConditions
          )}
        </div>
      }
      history={history}
    ></GeneralForm>
  );
};
Sharepoint.propTypes = {
  /**
   * Users email
   */
  email: PropTypes.string,
  /**
   * The configuration type of the form
   */
  configurationType: PropTypes.string,
  /**
   * Function update DEvOpsAnalytics form
   */
  updateSPForm: PropTypes.func,
  /**
   * Object containing current sharepoint form
   */
  values: PropTypes.object,
  /**
   * Contains selected instance from the stepper
   */
  aSelectedInstance: PropTypes.object,
  /**
   * Sets true if all fields are valid for submit
   */
  setIsSPAllValid: PropTypes.func,
};
const MSTP = (state, ownProps) => ({
  configurationType: state.fui.configurationType,
  values: state.fui.SPForm,
  aSelectedInstance: state.fui.aSelectedInstance,
  email: state.auth.userDetails.email,
});

export default connect(MSTP, { updateSPForm: fuiRedux.updateSPForm })(
  Sharepoint
);
