import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as fuiRedux from "../../../redux/fui/index";
import {
  fetchExternalClientDetails,
} from "../../../redux/fui/index";
import { Grid } from "@material-ui/core"; 
import {
  StyledTooltip,
  TextHelperWrapper,
  TextHelperTypography,
} from "../FuiStyles";
import {
  longTextEndDate,
  rootExistedHint,
  rootWrongFormatHint,
  quickConfigurationTextHelper,
} from "../Constants/LongText";
import { useDebounce } from "../../../helper/customizedHooks";
import { DateTextFieldCommonComponent } from "../DateTextFieldCommonComponent/DateTextFieldCommonComponent";
import * as FormField from "./IndividualFormFields";
import * as PlatformFormField from "./IndividualFormFields";
import TextsmsIcon from "@material-ui/icons/Textsms";
import {
  checkServiceNowTicket,
  checkWBSECode,
  checkBenefitsExpected,
  checkConcurrentUsers,
  checkDailyDataVolume,
  checkMonthlyTicketVolume,
} from "./CommonFunctions";
import GeneralForm from "./GeneralForm";

import BundleOrderApps from "./BundleOrderApps";
const aSelectedInstanceFields = {
  DMSId: { type: "string", fieldName: "dmsId" },
  ClientName: { type: "string", fieldName: "clientName" },
  DeployRegion: { type: "object", fieldName: "deployRegion" },
  SAPContractID: { type: "object", fieldName: "sapContractId" },
  AaamPlatformType: { type: "object", fieldName: "aaamPlatformType" },
  HighAvailability: { type: "string", fieldName: "highAvailability" },
};
/**
 * Splunk Cluster Form
 * @author Andres McLeod
 * @version 1.0
 * @see Used in component: PlatformLicenseStepper
 */

const SplunkCluster = ({
  values, // the value would be changed by user's input
  updateSPLUCLUForm, //the function to update DPForm values which is from redux
  updateASelectedInstanceByField,
  createdBy, // user's EID
  checkDomainAvaliable, // the function that check whether DomainContextRoot exist or not from redux
  isRootExist, // the value that show whether DomainContextRoot exist or not from redux
  setIsAllValid, // the function to check whether all validations are satisfieds
  selectedEnvironment,
  minDate,
  maxDate,
  isServiceEndDateInvalid,
  getSelectedEnvironment,
  getServiceEndDate,
  getNotesValue,
  notesValidations,
  configurationType,
  classes,
  history,
  email,
  idToken,
  isExternalClientLoading,
  externalClientData,
  externalClientError,
  fetchExternalClientDetails,
}) => {

  // open the dialog when user choose Yes in GDPR
  const config = configurationType === "quick";
  const [isMonthlyTicketVolumeValid, setIsMonthlyTicketVolumeValid] = useState(
    true
  );

  // check whether values are valid
  const [isServiceNowTicketValid, setIsServiceNowTicketValid] = useState(true);
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isDailyDataVolumeValid, setIsDailyDataVolumeValid] = useState(true);
  const [isConcurrentUsersValid, setIsConcurrentUsersValid] = useState(true);
  const [isBenefitsExpectedValid, setIsBenefitsExpectedValid] = useState(true);
  const [sapContractCodeLength, setSapContractCodeLength] = useState("0");
  const [domainContextRoot, setDomainContextRoot] = useState("[__]");
  const [regionCode, setRegionCode] = useState("[__]");
  const [customURL, setCustomURL] = useState("https://[__].mywizard-aiops.accenture.com/[__]");
  const isGDPR = values.GDPR === "yes";
  const isHichAv = values.HighAvailability === "yes";
  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";

  useEffect(() => {
    if (config) {
      setDomainContextRoot("[__]");
      setRegionCode("[__]");
    }
  }, [config]);

  if (config) {
    updateSPLUCLUForm("ClientName", "")
    updateSPLUCLUForm("EndToEndUId", "")
    updateSPLUCLUForm("Company", "")
    updateSPLUCLUForm("AvanadeCountry", "")
    updateSPLUCLUForm("AccentureCountry", "")
  }

  if (!config) {
    if (externalClientData) {
      updateSPLUCLUForm("ClientName", externalClientData[0])
      updateSPLUCLUForm("EndToEndUId", externalClientData[1])
      updateSPLUCLUForm("Company", externalClientData[2])
      updateSPLUCLUForm("AvanadeCountry", externalClientData[3])
      updateSPLUCLUForm("AccentureCountry", externalClientData[4])
    }
  }

  // const sapValue = document.getElementById("SAPContractID");
  // if (sapValue) {
  //   if (sapValue.value === "") {
  //     document.getElementById("clientDetails").style.display = "none";
  //     const verifiedIcon = document.getElementById("verifiedIcon")
  //     if (verifiedIcon) {
  //       verifiedIcon.style.display = "none";
  //     }
  //   } else {
  //     document.getElementById("clientDetails").style.display = "block";
  //   }
  // }

  if (!config) {
    (isDailyDataVolumeValid ||
      isConcurrentUsersValid ||
      isBenefitsExpectedValid) &&
      !isServiceEndDateInvalid && sapContractCodeLength == 10 && !externalClientError && isWBSECodeValid && isServiceNowTicketValid && isProjectNameValid
      ? setIsAllValid(true)
      : setIsAllValid(false);
  } else {
    (isServiceNowTicketValid ||
      isDailyDataVolumeValid ||
      isConcurrentUsersValid ||
      isBenefitsExpectedValid) &&
      !isServiceEndDateInvalid && isWBSECodeValid && isProjectNameValid
      ? setIsAllValid(true)
      : setIsAllValid(false);
  }

  // change validation status
  // isServiceNowTicketValid ||
  // isWBSECodeValid ||
  // isDailyDataVolumeValid ||
  // isConcurrentUsersValid ||
  // isBenefitsExpectedValid
  //   ? setIsAllValid(true)
  //   : setIsAllValid(false);

  // get user's input
  const handleChange = (event, fieldName) => {
    if (fieldName === "TentativeServiceEndDate") {
      updateSPLUCLUForm(fieldName, event);
      getServiceEndDate(event);
    } else {
      const { value } = event.target;
      event.preventDefault();
      updateSPLUCLUForm(fieldName, value);
      if (fieldName === "Environment") {
        getSelectedEnvironment(value);
      }
      if (fieldName === "RITM") {
        setIsServiceNowTicketValid(checkServiceNowTicket(value));
      }
      if (fieldName === "WBSEcode") {
        setIsWBSECodeValid(checkWBSECode(value));
      }
      if (fieldName === "ProjectName") {
        if (value.length > 32 || value.length < 3){
          setIsProjectNameValid(false)
        } else {
          setIsProjectNameValid(true)
        }
      }
      if (fieldName === "DailyDataVolume") {
        if (value[0] === "0") {
          setIsDailyDataVolumeValid(false);
        } else if (parseInt(value) > 200) {
          setIsDailyDataVolumeValid(false);
        } else {
          setIsDailyDataVolumeValid(checkDailyDataVolume(value));
        }
      }
      if (fieldName === "ConcurrentUsers") {
        setIsConcurrentUsersValid(checkConcurrentUsers(value));
      }
      if (fieldName === "BenefitsExpected") {
        if (value[0] === "0") {
          setIsBenefitsExpectedValid(false);
        } else {
          setIsBenefitsExpectedValid(checkBenefitsExpected(value));
        }
      }
      if (fieldName === "TentativeServiceEndDate") {
        getServiceEndDate(value);
      }
      if (fieldName === "RequestNotes") {
        if (value.length > 0 && value.length < 247) {
          getNotesValue({ emptyString: false, maxLength: false });
        } else if (value.length === 0) {
          getNotesValue({ emptyString: true, maxLength: false });
        } else if (value.length > 246) {
          getNotesValue({ emptyString: false, maxLength: true });
        }
      }
      if (fieldName === "TicketMonthlyVolume") {
        if (value[0] === "0") {
          setIsMonthlyTicketVolumeValid(false);
        } else {
          setIsMonthlyTicketVolumeValid(checkMonthlyTicketVolume(value));
        }
      }
      //update aSelectedInstance as other step relies on this data
      if (aSelectedInstanceFields[fieldName]) {
        const afieldDetails = aSelectedInstanceFields[fieldName];
        afieldDetails.type === "string" &&
          updateASelectedInstanceByField({
            field: afieldDetails.fieldName,
            value,
          });
        afieldDetails.type === "object" &&
          updateASelectedInstanceByField({
            field: afieldDetails.fieldName,
            value: {
              Value: value,
            },
          });
      }

      if (fieldName === "SAPContractID") {
        let sapLength = value.length
        setSapContractCodeLength(sapLength)
        if (sapLength == 10) {
          fetchExternalClientDetails(value)
        }
      }
      if (fieldName === "DeploymentRegion") {
        if (value == "sydney") {
          setRegionCode("dh")
        } else if (value == "virginia") {
          setRegionCode("dh1")
        } else if (value == "frankfurt") {
          setRegionCode("dh2")
        } else if (value == "singapore") {
          setRegionCode("dh3")
        } else if (value == "tokyo") {
          setRegionCode("dh4")
        } else if (value == "ireland") {
          setRegionCode("dh5")
        } else if (value == "london") {
          setRegionCode("dh6")
        } else {
          setRegionCode("[__]")
        }
      }
    }
  };

  // user can only input lower case letter and numbers in DomainContextRoot
  const handleChangeRoot = (event, fieldName) => {
    const re = /^[a-z0-9]*$/;
    const value = event.target.value;
    re.test(value) && updateSPLUCLUForm(fieldName, value);
    setDomainContextRoot(value)
  };

  const handleChangeRootSuggestions = (event, value, fieldName) => {
    const re = /^[a-z0-9]*$/;
    // const value = event.target.value;
    re.test(value) && updateSPLUCLUForm(fieldName, value);
    setDomainContextRoot(value)
  };

  // it would check whether the root has existed from DB once user stop typing longer than 0.3s
  const debouncedValue = useDebounce(domainContextRoot, 300);
  useEffect(() => {
    debouncedValue && checkDomainAvaliable(debouncedValue);
  }, [debouncedValue]);

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateSPLUCLUForm(fieldName, value);
  };

  updateSPLUCLUForm("ConfigurationType", configurationType);

  return (
    <>
    <GeneralForm
        FormId={15}
        ClientContractFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              {!config &&
                <Grid item xs={4}>
                  {PlatformFormField.SAPContractIDPlatformField(values, handleChange, externalClientError, isExternalClientLoading, sapContractCodeLength)}
                </Grid>}

              {sapContractCodeLength == 10 && sapContractCodeLength != 0 && !isExternalClientLoading && !externalClientError
                ? !config &&
                <Grid item xs={4}> {PlatformFormField.ClientNamePlatformField(values, handleChange, externalClientData[0])}</Grid>
                : ""
              }


              {sapContractCodeLength == 10 && !isExternalClientLoading && !externalClientError ?
                !config &&
                <Grid item xs={4}> {PlatformFormField.EndToEndUIdPlatformField(values, handleChange, externalClientData[1])}</Grid>
                : ""
              }

              <Grid item xs={4}>
                {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
              </Grid>
              <Grid item xs={4}>
                {FormField.WBSECodeField(handleChange, values, isWBSECodeValid)}
              </Grid >
              {!config &&
                <Grid item xs={4}>
                  {FormField.ServiceNowTicket(handleChange, values, isServiceNowTicketValid)}
                </Grid>}
              <Grid item xs={4}>
                {FormField.SecondaryContact(handleChange, values, config)}
              </Grid>

              <Grid item xs={12}>
                {PlatformFormField.RequestNotesPlatformField(
                  values,
                  handleChange,
                  notesValidations
                )}
              </Grid>
            </Grid>
          </div>
        }
        EnvironmentFields={
          <div>
            {!config ? <p> Expected Custom URL: <span style={{ color: " #0041F0" }}>https://{regionCode}.mywizard-aiops.com/{domainContextRoot}</span></p> : ""}
            <div style={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
              >

                <Grid item xs={3}>
                  {PlatformFormField.GDPRPlatformField(
                    values,
                    handleChange,
                    isGDPR,
                    classes
                  )}
                </Grid>

                <Grid item xs={3}>
                  {PlatformFormField.DeploymentRegionPlatformField(
                    values,
                    handleChange,
                    isGDPR
                  )}
                </Grid>

                <Grid item xs={6}>
                  {!config ? PlatformFormField.DomainContextRootPlatformFieldClient(
                    values,
                    handleChangeRootSuggestions,
                    // handleChangeRoot,
                    isRootExist,
                    rootExistedHint,
                    rootWrongFormatHint,
                    externalClientData,
                    domainContextRoot
                  ) : PlatformFormField.DomainContextRootPlatformField(
                    values,
                    handleChangeRoot,
                    isRootExist,
                    rootExistedHint
                  )}
                </Grid>
                {!config &&
                  <Grid item xs={6}>
                    {PlatformFormField.DailyDataVolumePlatformField(
                      values,
                      handleChange,
                      isConcurrentUsersValid
                    )}
                  </Grid>
                }
                <Grid item xs={3}>
                  {PlatformFormField.EnvironmentPlatformField(values, handleChange)}
                </Grid>
                {values.Environment === "Production" &&
                  <Grid item xs={3}>
                    {PlatformFormField.TierPlatformField(values, handleChange)}
                  </Grid>
                }
              </Grid>
            </div>
          </div>
        }
        ApplicationFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={6}>
                {FormField.AdminDistributionGroupField(handleChange, values)}
              </Grid>
              {!config &&
                <Grid item xs={6}>
                  {FormField.UsersDistributionGroupField(handleChange, values)}
                </Grid>
              }
              <Grid item xs={6}>
                <StyledTooltip
                  title={longTextEndDate}
                  placement="left-start"
                  noValidate
                >
                  <DateTextFieldCommonComponent
                    tentativeServiceEndDate={values.TentativeServiceEndDate}
                    selectedEnvironment={selectedEnvironment}
                    isServiceEndDateInvalid={isServiceEndDateInvalid}
                    minDate={minDate}
                    maxDate={maxDate}
                    handleChange={handleChange}
                  />
                </StyledTooltip>
              </Grid>
              {!config && <Grid item xs={6}></Grid>}
              <Grid item xs={6}>
              <BundleOrderApps
              classes={classes}
              handleChange={handleChange}
              config={config}
              values={values}
              updateForm={updateSPLUCLUForm}
              isMonthlyTicketVolumeValid={isMonthlyTicketVolumeValid}
              formName={"splunkcluster"}
            />
              </Grid>
            </Grid>
          </div>
        }
        history={history}
      ></GeneralForm>
      
    </>
  );
};

const MSTP = (state, ownProps) => ({
  values: state.fui.SPLUCLUForm,
  isRootExist: state.fui.isRootExist,
  configurationType: state.fui.configurationType,
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  externalClientData: state.fui.externalClientData,
  externalClientError: state.fui.externalClientError,
  isExternalClientLoading: state.fui.isExternalClientLoading,
});

export default connect(MSTP, {
  updateSPLUCLUForm: fuiRedux.updateSPLUCLUForm,
  updateASelectedInstanceByField: fuiRedux.updateASelectedInstanceByField,
  checkDomainAvaliable: fuiRedux.checkRootExisted,
  fetchExternalClientDetails,
})(withRouter(SplunkCluster));
