import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as fuiRedux from "../../../redux/fui/index";
import {
  fetchExternalClientDetails,
} from "../../../redux/fui/index";
import { Avatar } from "@material-ui/core";
import {
  StyledTooltip,
  TextHelperTypography,
  TextHelperWrapper,
} from "../FuiStyles";
import {
  longTextEndDate,
  rootExistedHint,
  rootWrongFormatHint,
  quickConfigurationTextHelper,
} from "../Constants/LongText";
import { useDebounce } from "../../../helper/customizedHooks";
import { DateTextFieldCommonComponent } from "../DateTextFieldCommonComponent/DateTextFieldCommonComponent";
import {
  checkServiceNowTicket,
  checkWBSECode,
  checkBenefitsExpected,
  checkConcurrentUsers,
  checkDailyDataVolume,
} from "./CommonFunctions";
import * as FormField from "./IndividualFormFields";
import * as PlatformFormField from "./IndividualFormFields";
import TextsmsIcon from "@material-ui/icons/Textsms";
import GeneralForm from "./GeneralForm";
import BundleOrderApps from "./BundleOrderApps";
import { Grid } from "@material-ui/core";

/**
 * Managed Platform Form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: PlatformLicenseStepper
 */

const ManagedPlatform = ({
  values, // the value would be changed by user's input
  updateMPForm, //the function to update DPForm values which is from redux
  updateASelectedInstanceByField,
  createdBy, // user's EID
  isRootExist,
  checkDomainAvaliable,
  setIsAllValid, // the function to check whether all validations are satisfieds
  selectedEnvironment,
  getSelectedEnvironment,
  minDate,
  maxDate,
  getServiceEndDate,
  isServiceEndDateInvalid,
  getNotesValue,
  notesValidations,
  configurationType,
  classes,
  history,
  email,
  idToken,
  isExternalClientLoading,
  externalClientData,
  externalClientError,
  fetchExternalClientDetails,
  isLoading
}) => {

  const config = configurationType === "quick";

  // check whether values are valid
  const [isServiceNowTicketValid, setIsServiceNowTicketValid] = useState(true);
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isDailyDataVolumeValid, setIsDailyDataVolumeValid] = useState(true);
  const [isConcurrentUsersValid, setIsConcurrentUsersValid] = useState(true);
  const [isBenefitsExpectedValid, setIsBenefitsExpectedValid] = useState(true);
  const [isMonthlyTicketVolumeValid] = useState(true);
  const [sapContractCodeLength, setSapContractCodeLength] = useState("0");
  const [domainContextRoot, setDomainContextRoot] = useState("[__]");
  const [regionCode, setRegionCode] = useState("[__]");
  const [customURL, setCustomURL] = useState("https://[__].mywizard-aiops.accenture.com/[__]");

  useEffect(() => {
    updateASelectedInstanceByField({
      field: "aaamPlatformType",
      value: {
        Value: "myWizard AiOps Managed Platform",
      },
    });
  }, []);

  useEffect(() => {
    if (config) {
      setDomainContextRoot("[__]");
      setRegionCode("[__]");
    }
  }, [config]);

  if (config) {
    updateMPForm("ClientName", "")
    updateMPForm("EndToEndUId", "")
    updateMPForm("Company", "")
    updateMPForm("AvanadeCountry", "")
    updateMPForm("AccentureCountry", "")
  }

  if (!config) {
    if (externalClientData) {
      updateMPForm("ClientName", externalClientData[0])
      updateMPForm("EndToEndUId", externalClientData[1])
      updateMPForm("Company", externalClientData[2])
      updateMPForm("AvanadeCountry", externalClientData[3])
      updateMPForm("AccentureCountry", externalClientData[4])
    }
  }

  // const sapValue = document.getElementById("SAPContractID");
  // if (sapValue) {
  //   if (sapValue.value === "") {
  //     document.getElementById("clientDetails").style.display = "none";
  //     const verifiedIcon = document.getElementById("verifiedIcon")
  //     if (verifiedIcon) {
  //       verifiedIcon.style.display = "none";
  //     }
  //   } else {
  //     document.getElementById("clientDetails").style.display = "block";
  //   }
  // }

  // ( isServiceNowTicketValid ||
  //   isWBSECodeValid ||
  //   isDailyDataVolumeValid ||
  //   isConcurrentUsersValid ||
  //   isBenefitsExpectedValid) &&
  // !isServiceEndDateInvalid 
  //   ? setIsAllValid(true)
  //   : setIsAllValid(false);

  if (!config) {
    (isDailyDataVolumeValid ||
      isConcurrentUsersValid ||
      isBenefitsExpectedValid) &&
      !isServiceEndDateInvalid && sapContractCodeLength == 10 && !externalClientError && isWBSECodeValid && isServiceNowTicketValid && isProjectNameValid
      ? setIsAllValid(true)
      : setIsAllValid(false);
  } else {
    (isServiceNowTicketValid ||
      isDailyDataVolumeValid ||
      isConcurrentUsersValid ||
      isBenefitsExpectedValid) &&
      !isServiceEndDateInvalid && isWBSECodeValid && isProjectNameValid
      ? setIsAllValid(true)
      : setIsAllValid(false);
  }


  const handleChange = (event, fieldName) => {
    if (fieldName === "TentativeServiceEndDate") {
      updateMPForm(fieldName, event);
      getServiceEndDate(event);
    } else {
      const value = event.target.value;
      event.preventDefault();
      updateMPForm(fieldName, value);

      if (fieldName === "Environment") {
        getSelectedEnvironment(value);
      }
      if (fieldName === "RITM") {
        setIsServiceNowTicketValid(checkServiceNowTicket(value));
      }
      if (fieldName === "WBSEcode") {
        setIsWBSECodeValid(checkWBSECode(value));
      }
      if (fieldName === "ProjectName") {
        if (value.length > 32 || value.length < 3) {
          setIsProjectNameValid(false)
        } else {
          setIsProjectNameValid(true)
        }
      }
      if (fieldName === "DailyDataVolume") {
        if (value[0] === "0") {
          setIsDailyDataVolumeValid(false);
        } else if (parseInt(value, 10) > 200) {
          setIsDailyDataVolumeValid(false);
        } else {
          setIsDailyDataVolumeValid(checkDailyDataVolume(value));
        }
      }
      if (fieldName === "ConcurrentUsers") {
        setIsConcurrentUsersValid(checkConcurrentUsers(value));
      }
      if (fieldName === "BenefitsExpected") {
        if (value[0] === "0") {
          setIsBenefitsExpectedValid(false);
        } else {
          setIsBenefitsExpectedValid(checkBenefitsExpected(value));
        }
      }
      if (fieldName === "TentativeServiceEndDate") {
        getServiceEndDate(value);
      }
      if (fieldName === "RequestNotes") {
        if (value.length > 0 && value.length < 247) {
          getNotesValue({ emptyString: false, maxLength: false });
        } else if (value.length === 0) {
          getNotesValue({ emptyString: true, maxLength: false });
        } else if (value.length > 246) {
          getNotesValue({ emptyString: false, maxLength: true });
        }
      }
      if (fieldName === "SAPContractID") {
        let sapLength = value.length
        setSapContractCodeLength(sapLength)
        if (sapLength == 10) {
          fetchExternalClientDetails(value)
        }
      }
      if (fieldName === "DeploymentRegion") {
        if (value == "sydney") {
          setRegionCode("dh")
        } else if (value == "virginia") {
          setRegionCode("dh1")
        } else if (value == "frankfurt") {
          setRegionCode("dh2")
        } else if (value == "singapore") {
          setRegionCode("dh3")
        } else if (value == "tokyo") {
          setRegionCode("dh4")
        } else if (value == "ireland") {
          setRegionCode("dh5")
        } else if (value == "london") {
          setRegionCode("dh6")
        } else {
          setRegionCode("[__]")
        }
      }
    }
  };

  // user can only input lower case letter and numbers in DomainContextRoot
  const handleChangeRoot = (event, fieldName) => {
    const re = /^[a-z0-9]*$/;
    const value = event.target.value;
    re.test(value) && updateMPForm(fieldName, value);
    setDomainContextRoot(value)
  };

  const handleChangeRootSuggestions = (event, value, fieldName) => {
    const re = /^[a-z0-9]*$/;
    // const value = event.target.value;
    re.test(value) && updateMPForm(fieldName, value);
    setDomainContextRoot(value)
  };

  // it would check whether the root has existed from DB once user stop typing longer than 0.3s
  const debouncedValue = useDebounce(domainContextRoot, 300);
  useEffect(() => {
    debouncedValue && checkDomainAvaliable(debouncedValue);
  }, [debouncedValue]);

  const isGDPR = values.GDPR === "yes";
  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updateMPForm("ConfigurationType", configurationType);

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateMPForm(fieldName, value);
  };

  return (
    <>
      <GeneralForm
        FormId={9}
        ClientContractFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              {!config &&
                <Grid item xs={4}>
                  {PlatformFormField.SAPContractIDPlatformField(values, handleChange, externalClientError, isExternalClientLoading, sapContractCodeLength)}
                </Grid>}

              {sapContractCodeLength == 10 && sapContractCodeLength != 0 && !isExternalClientLoading && !externalClientError
                ? !config &&
                <Grid item xs={4}> {PlatformFormField.ClientNamePlatformField(values, handleChange, externalClientData[0])}</Grid>
                : ""
              }


              {sapContractCodeLength == 10 && !isExternalClientLoading && !externalClientError ?
                !config &&
                <Grid item xs={4}> {PlatformFormField.EndToEndUIdPlatformField(values, handleChange, externalClientData[1])}</Grid>
                : ""
              }

              <Grid item xs={4}>
                {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
              </Grid>
              <Grid item xs={4}>
                {FormField.WBSECodeField(handleChange, values, isWBSECodeValid)}
              </Grid >
              {!config &&
                <Grid item xs={4}>
                  {FormField.ServiceNowTicket(handleChange, values, isServiceNowTicketValid)}
                </Grid>}
              <Grid item xs={4}>
                {FormField.SecondaryContact(handleChange, values, config)}
              </Grid>

              <Grid item xs={12}>
                {PlatformFormField.RequestNotesPlatformField(
                  values,
                  handleChange,
                  notesValidations
                )}
              </Grid>
            </Grid>
          </div>
        }
        EnvironmentFields={
          <div>
            {!config ? <p> Expected Custom URL: <span style={{ color: " #0041F0" }}>https://{regionCode}.genwizardsd.com/{domainContextRoot}</span></p> : ""}
            <div style={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
              >

                <Grid item xs={3}>
                  {PlatformFormField.GDPRPlatformField(
                    values,
                    handleChange,
                    isGDPR,
                    classes
                  )}
                </Grid>

                <Grid item xs={3}>
                  {PlatformFormField.DeploymentRegionPlatformField(
                    values,
                    handleChange,
                    isGDPR
                  )}
                </Grid>

                <Grid item xs={6}>
                  {!config ? PlatformFormField.DomainContextRootPlatformFieldClient(
                    values,
                    handleChangeRootSuggestions,
                    // handleChangeRoot,
                    isRootExist,
                    rootExistedHint,
                    rootWrongFormatHint,
                    externalClientData,
                    domainContextRoot,
                    isLoading
                  ) : PlatformFormField.DomainContextRootPlatformField(
                    values,
                    handleChangeRoot,
                    isRootExist,
                    rootExistedHint,
                    isLoading
                  )}
                </Grid>
                {!config &&
                  <Grid item xs={6}>
                    {PlatformFormField.DailyDataVolumePlatformField(
                      values,
                      handleChange,
                      isConcurrentUsersValid
                    )}
                  </Grid>
                }
                <Grid item xs={3}>
                  {PlatformFormField.EnvironmentPlatformField(values, handleChange)}
                </Grid>
              </Grid>
            </div>
          </div>
        }
        ApplicationFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={6}>
                {FormField.AdminDistributionGroupField(handleChange, values)}
              </Grid>
              {!config &&
                <Grid item xs={6}>
                  {FormField.UsersDistributionGroupField(handleChange, values)}
                </Grid>
              }
              <Grid item xs={6}>
                <StyledTooltip
                  title={longTextEndDate}
                  placement="left-start"
                  noValidate
                >
                  <DateTextFieldCommonComponent
                    tentativeServiceEndDate={values.TentativeServiceEndDate}
                    selectedEnvironment={selectedEnvironment}
                    isServiceEndDateInvalid={isServiceEndDateInvalid}
                    minDate={minDate}
                    maxDate={maxDate}
                    handleChange={handleChange}
                  />
                </StyledTooltip>
              </Grid>
              {!config && <Grid item xs={6}></Grid>}
              <Grid item xs={6}>
                <BundleOrderApps
                  classes={classes}
                  handleChange={handleChange}
                  config={config}
                  values={values}
                  updateForm={updateMPForm}
                  isMonthlyTicketVolumeValid={isMonthlyTicketVolumeValid}
                  formName={"managed"}
                />
              </Grid>
            </Grid>
          </div>
        }
        history={history}
      ></GeneralForm>

    </>
  );
};

const MSTP = (state, ownProps) => ({
  values: state.fui.MPForm,
  isRootExist: state.fui.isRootExist,
  configurationType: state.fui.configurationType,
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  externalClientData: state.fui.externalClientData,
  externalClientError: state.fui.externalClientError,
  isExternalClientLoading: state.fui.isExternalClientLoading,
  isLoading: state.fui.isLoading,
});

export default connect(MSTP, {
  clearMPForm: fuiRedux.clearMPForm,
  updateMPForm: fuiRedux.updateMPForm,
  updateASelectedInstanceByField: fuiRedux.updateASelectedInstanceByField,
  checkDomainAvaliable: fuiRedux.checkRootExisted,
  fetchExternalClientDetails,
})(withRouter(ManagedPlatform));
