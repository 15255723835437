import React, {
  Fragment,
  useEffect,
  // , useState
} from "react";
import Navbar from "../CommonNavbar/CommonNavbar";
import ResponsiveSidebar from "../ResponsiveSidebar/ResponsiveSidebar";
import {
  LoyoutWrapperContent,
  LayoutResponsiveSidebar,
  LayoutContentWrapper,
  //   StyledToastContainer,
} from "./StyledCommonLayout";
import { connect } from "react-redux";
import { clearLocalComponentData } from "../../../redux/fui/index";
import {
  fetchServersInstances,
  isCheckboxServiceEndDateSelectedAction,
} from "../../../redux/ServersReducer/ServersActions";

import { fetchInstanceData } from "../../../redux/fui/index";

// import { useCommonSidebarStyles } from "../makeStylesMaterialUi/CommonMakeStyles";

import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";
import { tokenValidation } from "../../../helper/customizedHooks";
import { Footer } from "../Footer";

const CommonLayout = (props) => {
  const {
    email,
    idToken,
    children,
    screenName,
    history,
    fetchServersInstances,
    fetchInstanceData,
  } = props;

  /**
   * Common Layout of the Page
   * @author Carlos Montes
   * @version 1.0
   * @see Used in component: routes.js
   */

  //   const serviceEndDateClasses = useCommonSidebarStyles();

  useEffect(() => {
    setInterval(() => {
      tokenValidation();
    }, 4000);
  }, []);

  useEffect(() => {
    const eId = email === undefined ? undefined : email.split("@")[0];
    if (eId && idToken) {
      fetchServersInstances(eId, idToken);
      fetchInstanceData(email, idToken);
    }
  }, [email, screenName, idToken]);

  return (
    <div>
      { history.location.pathname.includes("recommendations") || history.location.pathname.includes("approvals") ? 
        <Fragment>
          <LoyoutWrapperContent>
            <LayoutContentWrapper style={{ height: "90vh", paddingTop: "2em", paddingLeft: "3.5em" }}>
              {children}
            </LayoutContentWrapper>
          </LoyoutWrapperContent>
        </Fragment> : 
        <Fragment>
          <Navbar
            screenName={
              history.location.pathname === "/"
                ? "Dashboard"
                : history.location.pathname.includes("/servers")
                ? "Servers"
                : history.location.pathname.includes("/fui")
                ? "Fulfillment"
                : history.location.pathname.includes("/ssh")
                ? "SSH Management"
                : history.location.pathname.includes("/admin")
                ? "Admin"
                : history.location.pathname.includes("/genwizard")
                ? "Genwizard"
                : "FAQ"
            }
          />
          <LoyoutWrapperContent>
            <LayoutResponsiveSidebar>
              <ResponsiveSidebar currentRoute={history.location.pathname} />
            </LayoutResponsiveSidebar>
            <LayoutContentWrapper style={{ height: "90vh", paddingTop: "1em" }}>
              {children}
            </LayoutContentWrapper>
          </LoyoutWrapperContent>
          {/* <Footer /> */}
        </Fragment>
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.userDetails.email
    ? state.auth.userDetails.email
        .replace(/@[^@]+$/, "")
        .replace(".", " ")
        .split(" ")
        .map(function(item) {
          return item[0];
        })
        .join("")
        .toUpperCase()
    : "",
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  isLoading: state.sidebarReducer.isLoading,
  sidebar_arrays: state.sidebarReducer.sidebar_arrays,
  sidebar_numbers: state.sidebarReducer.sidebar_numbers,
  isCheckboxServiceEndDateSelected:
    state.servers.isCheckboxServiceEndDateSelected,
  activeAccount: state.auth.activeAccount,
});

export default connect(mapStateToProps, {
  clearLocalComponentData,
  fetchServersInstances,
  isCheckboxServiceEndDateSelectedAction,
  fetchInstanceData,
})(CommonLayout);
