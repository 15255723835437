import { images } from "../../../assets/index";

export const ProductsInformationMcc = {
  products: [
    {
      id: "4",
      routeName: "plainInstanceMcc",
      productName: "Plain Instance MCC",
      productDescription:
        "Plain Instance Provisioning. By placing order for a plain instance what you get is a plain AWS instance with no splunk installed in it.",
      background: "#72d1e0",
      type: "Instance",
      backgroundImage: images.PlainInstance,
      version: "NA"
    },
  ],
};

export const ProductsInformationPam = {
  products: [
    {
      id: "4",
      routeName: "pamInstance",
      productName: "PAM Provisioning",
      productDescription:
        "PAM provisioning will facilitate the automated deployment of ATR + PAM APIs, along with one or more applications in the Phoenix suite of applications.",
      background: "#72d1e0",
      type: "Instance",
      backgroundImage: images.PlainInstance,
      version: "NA"
    },
    {
      id: "5",
      routeName: "automationJourney",
      productName: "Automation Journey",
      productDescription:
        "Automation Journey manager is a one-stop shop to manage automation plan execution. It provides a change management framework to bring governance to the entire automation journey, thereby achieving traceable automation value and outcomes.",
      background: "#72d1e0",
      type: "App",
      backgroundImage: images.AutomationJourney,
      version: "4.3"
    },
    {
      id: "6",
      routeName: "automationPlanner",
      productName: "Automation Planner",
      productDescription:
        "Automation Planner recommends the right assets for every opportunity and in the process helps to create automation blueprints. It assists in creating targeted savings for each automation asset, employed for the desired automation opportunity.",
      background: "#72d1e0",
      type: "App",
      backgroundImage: images.AutomationPlanner,
      version: "4.3"
    },
    // {
    //   id: "7",
    //   routeName: "intelligentOpportunityMiner",
    //   productName: "Intelligent Opportunity Miner",
    //   productDescription:
    //     "Intelligent Opportunity Miner will be installed on the selected PAM instance.",
    //   background: "#72d1e0",
    //   type: "App",
    //   backgroundImage: images.IntelligentOpportunityMiner,
    //   version: "5.0"
    // },
    {
      id: "7",
      routeName: "guidedTicketResolver",
      productName: "Guided Ticket Resolver",
      productDescription:
        "Guided  Ticket Resolver will be installed on the selected PAM instance.",
      background: "#72d1e0",
      type: "App",
      backgroundImage: images.GuidedTicketResolver,
      version: "4.3"
    },
    // {
    //   id: "9",
    //   routeName: "klewer",
    //   productName: "Klewer",
    //   productDescription:
    //     "Klewer will be installed on the selected PAM instance.",
    //   background: "#72d1e0",
    //   type: "App",
    //   backgroundImage: images.Klewer,
    //   version: "1.2"
    // },
    // {
    //   id: "10",
    //   routeName: "quasar",
    //   productName: "Quasar",
    //   productDescription:
    //   "Quasar will be installed on the selected PAM instance.",
    //   background: "#72d1e0",
    //   type: "App",
    //   backgroundImage: images.Quasar,
    //   version: "1.2"
    // },
    // {
    //   id: "11",
    //   routeName: "virtualDataScientist",
    //   productName: "Virtual Data Scientist",
    //   productDescription:
    //     "Virtual Data Scientist will be installed on the selected PAM instance.",
    //   background: "#72d1e0",
    //   type: "App",
    //   backgroundImage: images.VirtualDataScientist,
    //   version: "5.0"
    // },
  ],
};
