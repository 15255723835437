export const longTextDNS = `
  DMS ID, SAP CONTRACT ID and CUSTOMER ENTERPRISE ID are used to identify the base platform. Once the Base platform is installed, please note that same combination (DMS ID + SAP CONTRACT ID + CUSTOMER ENTERPRISE ID) to be provided while requesting any of the Applications on top of the same Base platform.
`;

export const longTextUser = `Enter AD group or enterprise ID of members who will be the secondary contact for any concern related to ordered platform.`;

// export const secondaryContactText = `Enter only one AD group of members who will be the secondary point of contact for any communication related to ordered platform. Refrain using any special character other than .- used for AD group.`;

export const secondaryContactText = `Enter only one Accenture enterprise ID or distribution group of the client. And if client is from external resource then use their dedicated email domain. Refrain using any special character other than the allowed characters in email format.`;

export const longTextRoot = `
  DOMAIN CONTEXT ROOT is used to form the URL while provisoning the Splunk platform. For example, if the DOMAIN CONTEXT ROOT is 'abc123' then the URL will be formed as http://dh.mywizard-aiops.com/abc123. Only numbers and lower-case letters are allowed.
`;

export const longTextEndDate = `
  Determines the expiration date of the provisioned platform.
`;

export const longTextNotes = `
  Provide a description of the purpose of the platform being provisioned.
`;

export const longTextOpenAiRegion = `
  The region in which the OpenAI services will be hosted. 
`;

export const longTextCustom = `
  Provide a description of the purpose of the license being provisioned.
`;

export const longTextChooseTheExistingInstance = `
  Choose the Existing Platform is available and required for multiple platform product in MAS only.
`;

export const longTextName = `
  INSTANCE NAME is available and required for multiple platform product in MAS only.
`;

export const longTextUrl = `
  INSTANCE URL is available and required for multiple platform product in MAS only.
`;

export const longTextLicenses = `
  At least one type of licenses must be selected.
`;

export const longTextDialogFlow = `
  Users who have valid value for token can enter it here.
`;

export const longTextDistributionGroup = `
  Group or Enterprise ID of the specified users who need to be contacted in future by myWizard AiOps.
`;

export const alertPartOne = `
You have selected GDPR (General Data Protection Regulation).
`;

export const alertPartTwo = `The GDPR geo-restriction applies only to platforms processing data and users from the following countries:`;

export const alertPartThree = `Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, The Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain and Sweden.`;

export const alertPartFour = `AWS Ireland hosting platform shall consequently be used for your provisioning.`;

export const alertPartFive = `If the data processed by your platforms is not sourced from, and platform users do not reside in the countries mentioned above, please select 'No' for GDPR to indicate this.`;

export const submitSuccess = `ORDER REQUEST IS SUCCESSFULLY PLACED`;

export const submitFailed = `ORDER REQUEST FAILED`;

export const submitFailure =
  "Oops! Your order is unsuccessful. Please try again.";

export const quickConfiguration = `Quick Configuration is used for developing and testing.`;

export const fullConfiguration = `Clients are recommended to use Full Configuration.`;

export const quickConfigurationTextHelper = `Please select Client Configuration if you intended to provision 'Ticket Analysis' app.`;

export const quickConfigurationTextHelperPlainInstance = `Please select FULL Configuration if you are the client.`;

export const rootExistedHint = `* This Domain Context Root is already in use, please change to another name. (Only Numbers and lower-case letter are allowed).`;

export const rootWrongFormatHint = `* Invalid Domain Context Root format. Only Numbers and lower-case letter are allowed. No spaces are allowed!`;
