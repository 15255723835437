import moment from "moment";

const commonFields = {
  EndToEndUId: "",
  DMSId: "",
  AAAMTermsConditions: "no",
  CustomerMobileNo: "",
  SAPContractID: "",
  DomainContextRoot: "",
  GeographicArea: "",
  GDPR: "no",
  DeploymentRegion: "",
  BenefitsExpected: "",
  Environment: "",
  Tier: "",
  TentativeServiceEndDate: moment()
    .add(1, "days")
    .format("YYYY-MM-DD"),
  DailyDataVolume: "",
  ConcurrentUsers: "",
  CustomerEnterpriseID: "",
  WBSEcode: "",
  ClientName: "",
  RITM: "",
  InstanceType: "Dedicated",
  OpenAI: "False",
  ProjectName: ""
};
const commonAppModel = {
  DeployRegion: "",
  VendorName: "CAM",
  AaamPlatformType: "",
  AdminDistributionGroup: "",
  Users: "",
  ServiceGroup: "",
  UsersDistributionGroup: "",
  RequestNotes: "",
};

const bundleOrderModel = {
  appProductUId: [],
  DevOpsAddonsGroup: "",
  TicketFeed: "",
  ServiceNowUrl: "",
  ServiceNowUserName: "",
  ServiceNowPassword: "",
  TicketMonthlyVolume: "",
};

export const TAFormModel = {
  ...commonFields,
  ...commonAppModel,
  E2EProjectId: "",
  TicketFeed: "",
  ServiceNowUrl: "",
  ServiceNowUserName: "",
  ServiceNowPassword: "",
  TicketMonthlyVolume: "",
};

export const DOAFormModel = {
  ...commonFields,
  ...commonAppModel,
  DevOpsAddonsGroup: "",
};

export const SPFormModel = {
  ...commonFields,
  ...commonAppModel,
};

export const REFormModel = {
  ...commonFields,
  ...commonAppModel,
};

const commonPlatformModel = {
  DeployRegion: "",
  VendorName: "CAM",
  DomainContextRoot: "",
  GDPR: "no",
  DeploymentRegion: "",
  GeographicArea: "",
  AdminDistributionGroup: "",
  Users: "",
  ServiceGroup: "",
  UsersDistributionGroup: "",
  BenefitsExpected: "",
  Environment: "",
  TentativeServiceEndDate: moment()
    .add(1, "days")
    .format("YYYY-MM-DD"),
  DailyDataVolume: "",
  ConcurrentUsers: "",
  RequestNotes: "",
  SecondaryPOC: "",
  InstanceType: "Dedicated",
};

export const ATRFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  // ConfigurationType: "full",
  AaamPlatformType: "myWizard AiOps Automatic Ticket Resolver Platform",
  Tier: "",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
  DialogFlow: "disabled",
  DialogFlowClientToken: "",
  DialogFlowDevToken: "",
  ChatGPT: "",
  EnableQuasar: "False",
  OpenAiRegion: ""
};

export const EventOpsFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  // ConfigurationType: "full",
  AaamPlatformType: "myWizard AiOps EventOps Platform",
  Tier: "",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
  DialogFlow: "disabled",
  DialogFlowClientToken: "",
  DialogFlowDevToken: "",
  // ChatGPT: "gpt-4",
  DeployServiceNowID: "False",
  DeployJiraID: "False",
  DeployEmailID: "False",
  DeployATRID: "False",
  InstanceType: "EventOps"
};

export const TuringFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  // ConfigurationType: "full",
  AaamPlatformType: "myWizard AiOps Managed Platform",
  Tier: "",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
  DialogFlow: "disabled",
  DialogFlowClientToken: "",
  DialogFlowDevToken: "",
  ChatGPT: "",
  OpenAiRegion: "",
  OpenAI: "True"
  // EnableMultisource: false
};

export const StormSeerFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  // ConfigurationType: "full",
  AaamPlatformType: "myWizard AiOps Stormseer Platform",
  Tier: "",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
  DialogFlow: "disabled",
  DialogFlowClientToken: "",
  DialogFlowDevToken: "",
};

export const ATRSaaSFormModel = {
  AAAMTermsConditions: "no",
  OrderUId: "",
  VendorName: "CAM",
  Status: "Placed",
  OrderItemUId: "",
  ProductUId: "07443833-aad2-4225-9683-9f8d8c11c3d7",
  DeploymentRegion: "sydney",
  ClientName: "",
  DomainContextRoot: "",
  WBSEcode: "",
  CustomerEnterpriseID: "",
  DryRun: "false",
  TeamsRelayEnabled: "false",
  TeamsClientId: "",
  TeamsClientSecret: "",
  TeamsBotName: "",
};

export const SplunkClusterFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  ...bundleOrderModel,
  // ConfigurationType: "full",
  Tier: "",
  AaamPlatformType: "myWizard AiOps Splunk Cluster Platform",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
  HighAvailability: "no",
  StackApplication: [],
};

export const Stack360FormModel = {
  ...commonFields,
  ...commonPlatformModel,
  ...bundleOrderModel,
  ConfigurationType: "full",
  Tier: "",
  AaamPlatformType: "myWizard AiOps Stack Multitenant Platform",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
  //   HighAvailability: "no",
  OrderType: "New",
  StackApplication: ["acn-alertcentre360"],
};

export const StormWatchFormModel = {
  ...commonFields,
  ...commonAppModel,
};

export const AutomationJourneyFormModel = {
  ...commonFields,
  ...commonAppModel,
};

export const AutomationPlannerFormModel = {
  ...commonFields,
  ...commonAppModel,
};

export const IntelligentOpportunityMinerFormModel = {
  ...commonFields,
  ...commonAppModel,
};

export const GuidedTicketResolverFormModel = {
  ...commonFields,
  ...commonAppModel,
};

export const KlewerFormModel = {
  ...commonFields,
  ...commonAppModel,
};

export const QuasarFormModel = {
  ...commonFields,
  ...commonAppModel,
};

export const VirtualDataScientistFormModel = {
  ...commonFields,
  ...commonAppModel,
};

export const DPFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  ...bundleOrderModel,
  // ConfigurationType: "full",
  Tier: "",
  AaamPlatformType: "myWizard AiOps Dedicated Platform",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
};

export const GWLFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  ...bundleOrderModel,
  // ConfigurationType: "full",
  Tier: "",
  AaamPlatformType: "GenWizard AD Platform",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
  AppsIntegration: ["Quasarpp"],
  DBType: "",
  OpenAI: "True",
  ChatGPT: "gpt-35-turbo-16k"
};

export const ADFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  ...bundleOrderModel,
  // ConfigurationType: "full",
  Tier: "gold",
  AaamPlatformType: "myWizard AiOps AD Platform",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
  DBType: "",
};

export const WPFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  ...bundleOrderModel,
  // ConfigurationType: "full",
  Tier: "",
  AaamPlatformType: "myWizard AiOps Dedicated Platform",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
};


export const MPFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  ...bundleOrderModel,
  AaamPlatformType: "myWizard AiOps Managed Platform",
  ProductInstanceUrl: null,
};
export const PIFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  // ConfigurationType: "full",
  AaamPlatformType: "Plain Instance",
};

export const PAMFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  // ConfigurationType: "full",
  AaamPlatformType: "PAM",
  EnableLandingPage: "",
  NewOrder: "true",
  PAMPlatformType: "",
};

export const UTKMFormModel = {
  ...commonFields,
  ...commonPlatformModel,
  // ConfigurationType: "full",
  AaamPlatformType: "myWizard AiOps UTKM Platform",
  Tier: "platinum",
  ProductInstanceUrl: null,
  CloudPlatform: "",
  MyClient: "",
  BaseOS: "",
  RDSMultiAZ: false,
  IsMultiZoneDeployment: false,
  DialogFlow: "disabled",
  DialogFlowClientToken: "",
  DialogFlowDevToken: "",
  ChatGPT: "gpt-4",
  KMApps: ""
};

export const AALFormModel = {
  ...commonFields,
  InstanceType: "",
  ChooseNewOrExistingInstance: "",
  ChooseTheExistingInstance: "Automation Anywhere Licenses Instance",
  InstanceName: "Automation Anywhere Licenses Instance",
  InstanceUrl: "None",
  DeploymentRegion: "singapore",
  AAversion: "",
  DomainContextRoot: "",
  LicenseUsage: "",
  NumOfControlRoomlicenses: "",
  NumOfRunnerlicenses: "",
  NumOfDeveloperlicenses: "",
  RequestNotes: "",
  ProductInstanceUId: "Automation Anywhere Licenses Instance",
  ProductInstanceName: "Automation Anywhere Licenses Instance",
  ProductInstanceUrl: null,
  VendorName: "CAM",
  ConfigurationType: "full",
};

export const OPSFormModel = {
  ...commonFields,
  VendorName: "CAM",
  DeploymentRegion: "",
  GeographicArea: "",
  BenefitsExpected: "",
  Environment: "",
  TentativeServiceEndDate: moment()
    .add(1, "days")
    .format("YYYY-MM-DD"),
  DailyDataVolume: "",
  ServiceGroup: "",
  AaamPlatformType: "myWizard AiOps On-Premise Service",
  SplitDataVolume : [],
  InfrastructureType : "",
  LicensePurpose : "",
  RequestNotes: "",
  AiOpsAsset: [],
};

export const appUrlModel = {
  OrderUId: "",
  ClientName: "",
  OrderItems: [
    {
      OrderItemUId: "",
      CreatedBy: "",
      CreatedDate: "",
      CreateDate: "",
      Status: "Placed",
      ProductUId: "17cf154b-9c35-43a0-9902-55b247ebda57",
      VendorName: "CAM",
      Notes: "",
      AccessLinkURL: "",
      Config: [],
    },
  ],
};
