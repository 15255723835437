import { useRef, useState, useEffect } from "react";
import axios from "axios";
import base from "../config";
import { headers } from "./api";

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Save callback function
  useEffect(() => {
    savedCallback.current = callback;
  });

  // build interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const tokenValidation = () => {
  const exp = localStorage.getItem("exp");
  let expirationDate_number = parseInt(exp, 10);
  const currentTime = new Date().getTime() / 1000;
  let currenTime_number = parseInt(currentTime, 10);

  if ( window.location.pathname.includes("auth1" ) || window.location.pathname.includes("recommendations" )){
    if (
      expirationDate_number &&
      currenTime_number &&
      currenTime_number < expirationDate_number
    ) {
      return true;
    } else if (
      expirationDate_number &&
      currenTime_number &&
      currenTime_number > expirationDate_number
    ) {
      window.location.href = process.env.REACT_APP_RECOMMENDATIONS_URL;
      localStorage.clear();
      return false;
    } else {
      window.location.href = process.env.REACT_APP_RECOMMENDATIONS_URL;
      localStorage.clear();
      return false;
    }
  } else if ( window.location.pathname.includes("auth2" ) || window.location.pathname.includes("approvals" )){
    if (
      expirationDate_number &&
      currenTime_number &&
      currenTime_number < expirationDate_number
    ) {
      return true;
    } else if (
      expirationDate_number &&
      currenTime_number &&
      currenTime_number > expirationDate_number
    ) {
      window.location.href = process.env.REACT_APP_APPROVALS_URL;
      localStorage.clear();
      return false;
    } else {
      window.location.href = process.env.REACT_APP_APPROVALS_URL;
      localStorage.clear();
      return false;
    }
  } else {
    if (
      expirationDate_number &&
      currenTime_number &&
      currenTime_number < expirationDate_number
    ) {
      return true;
    } else if (
      expirationDate_number &&
      currenTime_number &&
      currenTime_number > expirationDate_number
    ) {
      window.location.href = process.env.REACT_APP_LOGIN_URL;
      localStorage.clear();
      return false;
    } else {
      window.location.href = process.env.REACT_APP_LOGIN_URL;
      localStorage.clear();
      return false;
    }
  }
};

export const useDebounceUserExist = (
  value,
  delay,
  accountType,
  email,
  idToken,
  sshManagement_DebounceEmptyString,
  checkUserExistAction
) => (dispatch, getState) => {
  const activeAccount = getState().auth.activeAccount;

  const userManagement = `${base.urls.apis.sshNew}user-management`;
  useEffect(() => {
    const handler = setTimeout(() => {
      if (value) {
        const eId = email === undefined ? undefined : email.split("@")[0];
        let url = `${userManagement}?customer_enterprise_id=${eId}&user_exist=${value}&account_type=${accountType}&inititive=${activeAccount}`;
        return axios
          .get(url, {
            headers: { ...headers, Authorization: idToken },
          })
          .then((res) => {
            checkUserExistAction({ userExistInDB: res.data, apiError: false });
          })
          .catch((err) => {
            checkUserExistAction({
              userExistInDB: false,
              apiError: true,
              data: err,
            });
          });
      } else {
        sshManagement_DebounceEmptyString();
      }
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);
};
