import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import * as fuiRedux from "../../redux/fui/index";
import { tokenValidation } from "../../helper/customizedHooks";
import PropTypes from "prop-types";
import { SecondaryButton } from '../common/CommonComponents/Buttons';
import { CommonDialog } from "../common/CommonComponents/Dialog";
import {
    AcceptButton,
    DeclineButton,
} from "../common/CommonComponents/Buttons";
import { DialogActions, Button } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 370,
        height: 270,
        border: "1px solid black",
        borderRadius: "0px",
        boxShadow: "unset",
        padding: "0px 10px 10px 0px",
        color: "black",
    },
    action: {
        fontSize: "x-small",
        backgroundColor: "#7500C0",
        color: "#FFF",
        padding: "0.5em 1em",
        borderRadius: "40px"
    },
    subheader: {
        marginTop: "1%",
        paddingLeft: "1%",
        marginBottom: "0%",
        color: "black",
        textWrap: "nowrap"
    },
    viewButton: {
        borderColor: "#a100ff",
        color: "#a100ff",
        borderRadius: "0px",
        float: "right"
    },
    instanceTypeButton: {
        fontWeight: "500",
        color: "#a100ff",
        fontSize: "14px",
        paddingLeft: "8px"
    },
    menuOptions: {
        fontSize: "14px",
    },
    header: {
        fontWeight: 600
    },
    cardActions: {
        display: "block",
        padding: "10px 2px 10px 10px"
    }
}));


const ProductCard = ({ history, resetFuiState, product }) => {
    useEffect(() => {
        tokenValidation();
        resetFuiState();
    }, []);
    const classes = useStyles();

    const [openSplunkClusterWarningDialog, setOpenSplunkClusterWarningDialog] = useState(false);
    const [instanceType, setInstanceType] = useState(" ");
    const [camEnvironment, setCAMEnvironment] = useState("");

    const handleSplunkClusterWarningSubmit = (ProductsInfomation) => {
        //alert(ProductsInfomation)
        history.push(`/fui/order/splunkClusterPlatform`)
        // setOpenSplunkClusterWarningDialog(false);
    };

    const handleChangeInstanceType = (event) => {
        setInstanceType(event.target.value);
        if (event.target.value === "Container") {
            setCAMEnvironment("Production");
        }
    };

    return (
        <div>
            <Card className={classes.root}>
                <CardHeader
                    // action={
                    //   <p className={classes.action}>DEDICATED</p>
                    // }
                    classes={{
                        title: classes.header
                    }}
                    title={product.productName}
                    subheader={
                        <p className={classes.subheader}>
                            Version - {product.version}
                        </p>
                    }
                />
                <CardContent>
                    <Typography variant="body1" color="textPrimary" component="p" >
                        {product.productDescription.substring(0, 130).trimEnd() + '...'}
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    {product.productName === "Automatic Ticket Resolver" || product.productName === "Quasar++" ?
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                id="select"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.instanceTypeButton
                                }}
                                value={instanceType}
                                onChange={handleChangeInstanceType}
                                select>
                                <MenuItem className={classes.menuOptions} value=" " disabled>
                                    TYPE
                                </MenuItem>
                                <MenuItem className={classes.menuOptions} value="Dedicated">DEDICATED</MenuItem>
                                <MenuItem className={classes.menuOptions} value="Container">CONTAINER</MenuItem>
                            </TextField>
                        </FormControl>
                        : ""}
                    <SecondaryButton
                        disabled={(product.productName === "Automatic Ticket Resolver" || product.productName === "Quasar++") && instanceType === " " ? true : false}
                        placeholder="test"
                        label={"Create"}
                        className={classes.viewButton}
                        onClick={() => {
                            product.routeName === 'splunkClusterPlatform' ?
                                setOpenSplunkClusterWarningDialog(true) :
                                history.push({
                                    pathname: `/fui/order/${product.routeName}`,
                                    state: { InstanceType: `${instanceType}`, CAMPortalEnvironment: `${camEnvironment}` }
                                });
                            // history.push(`/fui/order/${product.routeName}`)
                        }}
                    />
                </CardActions>
            </Card>
            {openSplunkClusterWarningDialog && (
                <CommonDialog
                    open={openSplunkClusterWarningDialog}
                    onClose={() => setOpenSplunkClusterWarningDialog(false)}
                    title={"WARNING"}
                    dialogType={"servers"}
                    textContent={"If your daily data ingestion is less than 50Gb, go back to Fulfillment Order page and select Dedicated Platform instead of Splunk Cluster Platform. Do you still want to proceed in provisioning Splunk Cluster Platform?"}
                    dialogActions={
                        <DialogActions >
                            <DeclineButton
                                onClick={() => setOpenSplunkClusterWarningDialog(false)}
                                label={"No"}
                                disabled={false}
                            />
                            <AcceptButton
                                onClick={() => handleSplunkClusterWarningSubmit()}
                                label={"Yes"}
                            />
                        </DialogActions>
                    }
                ></CommonDialog>
            )}
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * History property of route library
     */
    history: PropTypes.object,
    /**
     * Redux function to clear the current fulfilement state
     */
    resetFuiState: PropTypes.func,
};

const mapStateToProps = (state) => ({
    belongingTo: state.auth.initiativesBelongTo,
});

export default connect(mapStateToProps, {
    resetFuiState: fuiRedux.resetFuiState,
})(withRouter(ProductCard));
